
import '@/plugins/composition-api'

import { useVModel } from '@vueuse/core';
import Konva from 'konva';
import { defineComponent, computed, PropType } from '@vue/composition-api'

import type { TemplateItem } from '@/use/templates/editor'
import useEditorConfig from '@/use/templates/editorConfig'
import useEditorSettings from '@/use/templates/editorSettings'

export default defineComponent({
  components: { MTemplateEditorImage: () => import('@/components/molecules/template-editor/m-template-editor-image.vue') },
  props: {
    value: {
      type: Array as PropType<TemplateItem[]>,
      required: false,
      default: () => []
    },
    shapes: {
      type: Array as PropType<Konva.Shape[]>,
      required: false,
      default: () => []
    },
    selectedShapes: {
      type: Array as PropType<Konva.Shape[]>,
      required: false,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const model = useVModel(props, 'value', emit, { eventName: 'input' })
    const selectedShapes = useVModel(props, 'selectedShapes', emit)

    const selectedItem = computed(() => {
      if (selectedShapes.value.length === 1) {
        return model.value.find(item => item.config.id === selectedShapes.value[0].id())
      }
      return undefined
    })

    const { templateItemNames } = useEditorConfig()
    const { shapeFields, fields } = useEditorSettings(selectedItem)

    return { templateItemNames, shapeFields, fields, selectedItem }
  }
})
