var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-editor-settings"},[(_vm.selectedShapes.length < 1)?[_c('div',{staticClass:"template-editor-settings__info"},[_vm._v(" "+_vm._s(_vm.$t("panel.event.modules.receptionConfig.pickElementToEdit"))+" ")])]:(_vm.selectedItem)?[_c('div',{staticClass:"template-editor-settings__title"},[_vm._v(" "+_vm._s(_vm.templateItemNames[_vm.selectedItem.type])+" ")]),_c('div',{staticClass:"template-editor-settings__wrapper"},_vm._l((_vm.shapeFields[_vm.selectedItem.type]),function(fieldName){return _c('div',{key:fieldName,staticClass:"template-editor-settings__field",class:{
          'template-editor-settings__field--full': _vm.fields[fieldName].full,
        }},[(
            _vm.fields[fieldName].field &&
              _vm.fields[fieldName].field.type === 'image'
          )?_c('m-template-editor-image',_vm._b({key:_vm.selectedItem.config.id,attrs:{"value":_vm.selectedItem.config[fieldName] || _vm.fields[fieldName].default},on:{"input":function($event){_vm.selectedItem.config[fieldName] = $event},"resize":function (value) {
              _vm.selectedItem.config.width = value.width;
              _vm.selectedItem.config.height = value.height;
            }}},'m-template-editor-image',_vm.fields[fieldName].field,false)):(
            _vm.fields[fieldName].field &&
              _vm.fields[fieldName].field.type === 'select'
          )?_c('v-autocomplete',_vm._b({key:_vm.selectedItem.config.id,attrs:{"value":_vm.selectedItem.config[fieldName] || _vm.fields[fieldName].default},on:{"input":function($event){_vm.selectedItem.config[fieldName] = $event}}},'v-autocomplete',Object.assign({}, _vm.fields[fieldName].field,
            {items:
              typeof _vm.fields[fieldName].field.items === 'function'
                ? _vm.fields[fieldName].field.items()
                : _vm.fields[fieldName].field.items}),false)):(
            _vm.fields[fieldName].field &&
              _vm.fields[fieldName].field.type === 'number'
          )?_c('v-text-field',_vm._b({key:_vm.selectedItem.config.id,attrs:{"value":parseFloat(_vm.selectedItem.config[fieldName]) ||
              parseFloat(_vm.fields[fieldName].default)},on:{"input":function($event){_vm.selectedItem.config[fieldName] = parseFloat($event)}}},'v-text-field',_vm.fields[fieldName].field,false)):_c('v-text-field',_vm._b({key:_vm.selectedItem.config.id,attrs:{"value":_vm.selectedItem.config[fieldName] || _vm.fields[fieldName].default},on:{"input":function($event){_vm.selectedItem.config[fieldName] = $event}}},'v-text-field',_vm.fields[fieldName].field,false))],1)}),0)]:[_c('div',{staticClass:"template-editor-settings__info"},[_vm._v(" Wybrane elementy: "+_vm._s(_vm.selectedShapes.length)+" ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }